<template>
  <div class="auth-wrapper">
    <section class="auth-card">
      <div class="col-1">
        <img
          class="img-1"
          src="@/assets/images/login/login.png"
          alt=""
          width="30%"
          height="30%"
        />
        <h2>Welcome Back!</h2>
        <p>Login to accesss your account</p>
        <form action="" @submit.prevent="Confirmlogin">
          <!-- <p>Login to access</p> -->
          <Input
            label="Email"
            v-model="email"
            :validation="['required', 'emailRules']"
            @isValid="EmailIsValid"
            :checkValidationFlag="checkValidationFlag"
            type="email"
            icon="bx bx-envelope bx-tada"
          ></Input>

          <Input
            label="Password"
            v-model="password"
            :validation="['required']"
            @isValid="PasswordIsValid"
            :checkValidationFlag="checkValidationFlag"
            type="password"
            icon="bx bx-lock bx-tada"
          ></Input>
          <router-link :to="{ name: 'reset-password' }" class="forgot-password"
            >Forgot your password?</router-link
          >
          <VueRecaptcha
            class="recaptcha"
            @verify="successCaptcha"
            @expired="expireCaptcha"
            :sitekey="sitekey"
            ref="Recaptcha"
          />
          <p
            style="color: red; margin: 0; font-size: 13px"
            :style="`${loginClicked && !captcha ? 'opacity:1' : 'opacity:0'}`"
          >
            *Recaptcha Required
          </p>
          <button type="submit" class="auth-btn login-btn lt-hover">
            <Loading v-if="loading"></Loading>
            <div style="display: flex; align-items: center" v-else>
              Login
              <i class="bx bx-log-in-circle bx-burst"></i>
            </div>
          </button>
          <!-- <button > -->
          <router-link :to="{ name: 'landing' }" class="auth-btn back-btn lt-hover">
            Back to landing
            <i class="bx bx-left-arrow-circle bx-burst"></i>
          </router-link>
          <!-- </button> -->
          <Or class="or-line"></Or>
          <GoogleLogin name="Continue with Google"></GoogleLogin>
        </form>
        <div class="other-auth">
          <p>Dont have an account?</p>
          <routerLink :to="{ name: 'register' }"> Sign Up </routerLink>
        </div>
        <div class="socials">
          <i class="bx bxl-telegram"></i>
          <i class="bx bxl-twitter"></i>
          <i class="bx bxl-instagram-alt"></i>
        </div>
      </div>
      <div class="col-2">
        <img
          class="img-2"
          src="@/assets/images/login/login-logo.png"
          alt=""
          width="160%"
        />
      </div>
    </section>
    <!-- 2Fa modal -->
    <BaseModal
      :dialog="twoFaDialog"
      @close="twoFaDialog = false"
      title="Two-Factor Authentication"
    >
      <form action="" @submit.prevent="verifyLogin" class="two-fa-modal-form">
        <p>Enter the verification code generated by your mobile application.</p>
        <Input
          label="Verification code"
          v-model="enteredCode"
          :validation="['required']"
          @isValid="codeIsValid"
          :checkValidationFlag="checkValidationFlag"
          type="number"
          icon="bx bx-lock bx-tada"
        ></Input>
        <PanelBaseButton type="submit">
          <Loading v-if="loading"></Loading>
          <div v-else>Verify Code</div>
        </PanelBaseButton>
      </form>
    </BaseModal>
    <!-- 2Fa modal -->
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { captcha } from "@/mixins/captcha.js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      email: "",
      emailValidation: null,
      password: null,
      passValidation: null,
      checkValidationFlag: false,
      loading: false,
      loginClicked: false,
      //2fa login
      twoFaDialog: false,
      enteredCode: null,
      codeValidation: false,
    };
  },
  mixins: [captcha],
  components: { VueRecaptcha },
  computed: {
    ...mapGetters(["returnRoute"]),
  },
  methods: {
    ...mapActions(["login", "clearReturnRoute", "verifyTwoFa"]),
    EmailIsValid(v) {
      this.emailValidation = v;
    },
    PasswordIsValid(v) {
      this.passValidation = v;
    },
    codeIsValid(v) {
      this.codeValidation = v;
    },

    Confirmlogin() {
      if (this.emailValidation && this.passValidation) {
        this.loginClicked = true;
        if (this.captcha) {
          const loginData = {
            email: this.email,
            password: this.password,
            "g-recaptcha": this.captcha,
          };
          this.loading = true;
          this.login(loginData)
            .then((res) => {
              if (!res.data.google2fa) {
                //2fa auth not active and regular login done
                this.returnRouteHandle();
              } else {
                //2fa auth active and open the 2fa dialog
                this.twoFaDialog = true;
              }
            })
            .catch(() => {
              this.$refs.Recaptcha.reset();
              this.captcha = "";
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        this.checkValidationFlag = true;
      }
    },

    verifyLogin() {
      if (this.codeValidation) {
        const verifyPayload = {
          code: this.enteredCode,
          email: this.email,
        };
        this.loading = true;
        this.verifyTwoFa(verifyPayload)
          .then(() => {
            this.returnRouteHandle();
            this.twoFaDialog = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.checkValidationFlag = true;
      }
    },
    returnRouteHandle() {
      if (this.returnRoute) {
        this.$router
          .push({
            name: this.returnRoute.name.substring(12),
            params: { id: this.returnRoute.params.id },
          })
          .catch(() => {});
        this.clearReturnRoute();
      } else {
        this.$router.push({ name: "dashboard" }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_reg&login.scss";
.auth-wrapper {
  height: 104vh;
  .col-2 {
    background-position: left;
  }
}
</style>
